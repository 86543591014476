// AJAX-запросы
// import * as ajaxRequest from "./ajax/_ajaxRequest.js";

// Google reCaptcha
// import * as googleReCaptcha from "./googleReCaptcha/_googleRecaptcha.js";

// Cookies
// import * as cookies from "./cookies/_cookies.js";

// DOM
// import * as domDocument from "./dom/_dom.js";

// Site Search
// import * as siteSearch from "./site-search/_site-search.js";

// User Agent (check Bots or another)
// import * as userAgent from "./user-agent/_user-agent.js";

// User geoposition
// import * as geoposition from "./geoposition/_geoPosition.js";

// Forms scripts
// import * as forms from "./forms/forms.js";

// Shop scripts
// import * as shop from "./shop/shop.js";

// Users Modals
//import * as userModals from "./modal/_userMessage.js";

// Form Validate
//import * as ads from "./kassamassa/kassamassa.js";